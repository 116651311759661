























import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({ default: undefined }) number
  @Prop({ default: undefined }) text
  @Prop({ default: '' }) width
  @Prop({ default: true }) canClick
  @Prop({ default: false }) block
  @Prop({ default: false }) disabled
  @Prop({ default: false }) loading
  @Prop({ default: false }) warning
}
